import React from 'react';
import './App.css';

import { connect } from 'react-redux';
import { HashRouter, Switch, Route } from 'react-router-dom'; // withRouter

import Home from './HomeContainer';
import YourDetails from './YourDetailsContainer';
import Summary from './SummaryContainer';
import HowLongMightYourRetirementBe from './HowLongMightYourRetirementBeContainer';
import DesiredMonthlySpend from './DesiredMonthlySpendContainer';
import DesiredSpendVsSustainableIncome from './DesiredSpendVsSustainableIncomeContainer';

const mapStateToProps = (state: any) => (state.screen);
const mapDispatchToProps = (dispatch: any) => ({
  setContainerSize: (node: HTMLElement, _container: any) => {
    if (node) {
      const container = node.getBoundingClientRect();
      const { x, y, width, height, top, right, bottom, left } = container;
      const { x: _x, y: _y, width: _width, height: _height, top: _top, right: _right, bottom: _bottom, left: _left } = _container;
      if (x !== _x || y !== _y || width !== _width || height !== _height || top !== _top || right !== _right || bottom !== _bottom || left !== _left) {
        dispatch({
          type: 'SET_CONTAINER_SIZE',
          payload: {
            container: { x, y, width, height, top, right, bottom, left },
          }
        });
      }
    }
  },
});
const isIFrame = window.top !== window.self;
const style = isIFrame ? {} : { minHeight: '100vh' };

const App = (props: any) => (
  <div className="App container" ref={node => props.setContainerSize(node, props.container)} style={style}>
    <HashRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/your-details' component={YourDetails} />
        <Route exact path='/summary' component={Summary} />
        <Route exact path='/how-long-might-your-retirement-be' component={HowLongMightYourRetirementBe} />
        <Route exact path='/desired-monthly-spend-in-retirement' component={DesiredMonthlySpend} />
        <Route exact path='/desired-spend-vs-sustainable-income' component={DesiredSpendVsSustainableIncome} />
      </Switch>
    </HashRouter>
    <div className='copyright'>Copyright &copy; 2022 - Key Partnerships. This website is intended for intermediaries only and has not been approved for customer use.</div>
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
