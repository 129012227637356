import { getOutput } from './../services/LtvCalc';
import { findStatePensionAge } from './../services/StatePensionAge';
import { getAvgLifeExpectancy } from './../services/AvgLifeExpectancy';

const getDefaultBirthdate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 50);
  return date;
};

const calculateAge = (birthdate: Date) => {
  const diff = Date.now() - birthdate.getTime();
  const age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970);
};

// export const monthlySpend = {
//   councilTax: 426.51,
//   utilities: 134.66,
//   phone: 74.48,
//   foodDrink: 286.65,
//   eating: 169.3,
//   clothing: 124.27,
//   transport: 317.82,
//   leisure: 202.64,
//   holiday: 238.58,
//   medical: 95.26,
//   other: 125.57
// };

export const monthlySpend = {
  councilTax: 252.40,
  utilities: 175.00,
  phone: 74.40,
  foodDrink: 248.00,
  eating: 74.90,
  clothing: 42.40,
  transport: 203.60,
  leisure: 163.30,
  holiday: 39.20,
  medical: 134.80,
  other: 95.84
};

const partner = {
  age: 50,
  birthdate: getDefaultBirthdate(),
  gender: 'F',
  retAge: 60,
  statePension: true,
  statePensionAge: 65,
  statePensionAmount: 712.18,
  lifeExpectancy: getAvgLifeExpectancy({ birthdate: getDefaultBirthdate(), gender: 'F' }),
};

export const defaultInput = {
  age: 50,
  birthdate: getDefaultBirthdate(),
  gender: 'M',
  retAge: 65,
  saving: 20000,
  pension: 250000,
  statePension: true,
  statePensionAge: 65,
  statePensionAmount: 712.18, //164.35 * 52 / 12, // monthly?
  homeVal: 500000,
  percentHouse: 1,
  mortgageVal: 0,
  outstandingDebt: 15000,
  lifeExpectancy: getAvgLifeExpectancy({ birthdate: getDefaultBirthdate(), gender: 'M' }),
  withPartner: false,
  partner,
  monthlySpend,
  include: {
    savings: true,
    statePension: true,
    privatePension: true,
    lifetimeMortgage: false,
    lifetimeEligible: false // user confirms he is not eligible (Yes on control, means agrees that not eligible)
  }
};

export interface IInputOutput {
  input?: any,
  output?: any,
};

const updateInputOutput = (params: IInputOutput) => {
  const { input, output } = params;
  return { input, output };
};

const getLifeExpectancy = (params: IInputOutput) => {
  const { output } = params;
  const { avgRetirement } = output;
  const { avgLifeExpectancy } = avgRetirement;
  return avgLifeExpectancy;
};

const output = getOutput(defaultInput);
defaultInput.lifeExpectancy = getLifeExpectancy({ output });

const initialState = updateInputOutput({ input: defaultInput, output });

export default function (state = initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_INPUT': {
      const {field, value} = action.payload;
      const input = { ...state.input, [field] : value };
      if (field === 'birthdate' || field === 'gender') {
        input.age = calculateAge(input.birthdate);
        input.statePensionAge = findStatePensionAge(input.birthdate, input.gender);
      }
      if (field === 'age' || field === 'birthdate' || field === 'retAge' || field === 'gender') {
        input.lifeExpectancy = getAvgLifeExpectancy({ birthdate: input.birthdate, gender: input.gender });
      }
      if (field === 'avgLifeExpectancy') {
        // calculated output value is overriden by user's input
        input.customAvgLifeExpectancy = value;
      }
      const output = getOutput(input);
      updateInputOutput({ input, output });
      return { ...state, input, output};
    }
    case 'UPDATE_INCLUDE': {
      const {field, value} = action.payload;
      const include = { ...state.input.include, [field] : value }
      const input = { ...state.input, include: { ...include } };
      const output = getOutput(input);
      updateInputOutput({ input, output });
      return { ...state, input, output};
    }
    case 'UPDATE_PARTNER': {
      const {field, value} = action.payload;
      const partner = { ...state.input.partner, [field] : value }
      if (field === 'birthdate' || field === 'gender') {
        partner.age = calculateAge(partner.birthdate);
        partner.statePensionAge = findStatePensionAge(partner.birthdate, partner.gender);
      }
      const input = { ...state.input, partner: { ...partner } };
      const output = getOutput(input);
      updateInputOutput({ input, output });
      if (field === 'age' || field === 'birthdate' || field === 'retAge' || field === 'gender') {
        input.partner.lifeExpectancy = getAvgLifeExpectancy({ birthdate: partner.birthdate, gender: partner.gender });
      }
      return { ...state, input, output};
    }
    case 'UPDATE_MONTHLY_SPEND': {
      const {field, value} = action.payload;
      const monthlySpend = { ...state.input.monthlySpend, [field] : value }
      const input = { ...state.input, monthlySpend: { ...monthlySpend } };
      const output = getOutput(input);
      updateInputOutput({ input, output });
      return { ...state, input, output};
    }
    default:
  }
  return state
};
